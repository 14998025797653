/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
import { Divider } from "@mui/material";

import terapiaImg from "assets/images/terapia/terapia1.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid xs={12} sx={{ mb: "12px" }} textAlign="center">
          <MKTypography
            variant="h2"
            color="primary"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Historia de CAPPDI
          </MKTypography>
          <MKTypography
            variant="subtitle2"
            color="secondary"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            2013-2023
          </MKTypography>
        </Grid>
        <Grid sx={{ mb: "3rem" }}>
          <MKTypography mb={1.5} variant="body2">
            En el año 2006 nace “Su Pechito” un pequeño con discapacidad. Sus padres vivieron el
            reto de encontrar servicios terapéuticos integrales y accesibles. Inspirados en la
            historia de vida de su hijo, fundan CAPPDI A.C. en agosto del año 2013.
          </MKTypography>
          <MKTypography mb={1.5} variant="body2">
            Basados en la vocación profesional de nuestro equipo, el compromiso de los padres de
            familia, el respaldo de mesa directiva, donantes y aliados, hemos impactado en más de
            2000 niñas, niños, adolescentes, jóvenes, adultos y personas mayores que nacen y/o
            adquieren alguna condición de neurodiversidad. Mediante la aplicación de un plan
            terapéutico integral acompañamos la consolidación de sus proyectos de vida en conjunto
            con sus familias y su comunidad.
          </MKTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={12} sx={{ mx: "auto", textAlign: "left" }}>
          <MKTypography variant="h3" color="secondary" textGradient mb={2}>
            Que hacemos y que haremos
          </MKTypography>
        </Grid>
        <Grid sx={{ mb: "3rem" }}>
          <MKTypography mb={1.5} variant="body2">
            Somos una institución que brinda atención integral y formación a personas que viven con
            alguna condición de neurodiversidad.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Misión"
              review="Somos una familia neurodiversa comprometida con el bienestar integral de nuestros usuarios mediante servicios terapéuticos transdisciplinarios, asequibles y de calidad."
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="secondary"
              name="Visión"
              review="Seremos una comunidad neurodiversa, vanguardista en la construcción de una sociedad incluyente, digna y sostenible."
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Propósito "
              review="Persistiremos en convertirnos en un país que garantice las mismas oportunidades para todas las personas que viven con alguna condición de neurodiversidad y sus familias."
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
      <Container>
        <Grid xs={12} sx={{ mb: "12px" }} mb={2}>
          <MKTypography variant="h3" color="secondary" mb={6}>
            Principios de Trabajo
          </MKTypography>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Pasión"
                    description="Amor y profesionalismo por las actividades que desarrollamos"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Innovación"
                    description="Mejorar cada día los servicios que brindamos."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Apoyo al prójimo"
                    description="Todas las actividades que desarrollamos buscan contribuir de una manera positiva a nuestra sociedad."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Transversal longitudinal"
                    description="Nuestros programas pretenden ser transversales e impactar en el área física, social y emocional así como en las distintas etapas de desarrollo niñez, juventud y adultez de nuestros beneficiarios)."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={terapiaImg}
              title="¿Quieres formar parte?"
              description="Contamos con distintos medios para participar y ser parte de CAPPDI"
              action={{
                type: "internal",
                route: "/pages/lading-pages/contact-us",
                color: "info",
                label: "Descubre más",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
