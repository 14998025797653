import React from "react";
import { Grid, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";

const IntroSection = () => (
  <MKBox component="section" pt={12}>
    <Container>
      <Grid sx={{ mb: "1rem" }} pb={8}>
        <MKTypography
          variant="h2"
          color="secondary"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
          mb={4}
        >
          Nuestro equipo
        </MKTypography>
        <MKTypography mb={1.5} variant="body2">
          Rehabilitación y salud: Terapia física, Terapia ocupacional, Comunicación Humana,
          Enfermería, Nutrición, Acupuntura, Quiropráctico y Gerontología.
        </MKTypography>
        <MKTypography mb={1.5} variant="body2">
          Psicopedagogía: Pedagogía, Psicología y Educación Especial
        </MKTypography>
      </Grid>
      <Grid xs={12} sx={{ mb: "24px", textAlign: "right" }}>
        <MKTypography
          variant="h2"
          color="primary"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
          mb={4}
        >
          Población Objetivo
        </MKTypography>
      </Grid>
      <Grid>
        <MKTypography mb={1.5} variant="body2">
          Niñas, niños, adolescentes, jóvenes, adultos y personas mayores que viven con alguna
          condición de neurodiversidad desde los 2 años de edad.
        </MKTypography>
        <MKTypography mb={1.5} variant="body2">
          Condiciones, trastornos y/o síndromes con los que tenemos experiencia.
        </MKTypography>
        <MKTypography mb={1.5} variant="body2">
          Discapacidad Intelectual, Discapacidad Motriz, Condición del Espectro Autista, Trastorno
          de la Comunicación, Trastorno Específico del Aprendizaje (Dislexia, Dislalia,
          Discalculia), TDA/H Trastorno por Déficit de Atención con o sin Hiperactividad, Síndrome
          de Down, Síndromes genéticos, epilepsia, etc.
        </MKTypography>
      </Grid>

      <Grid mt={12}>
        <MKTypography
          variant="h2"
          color="secondary"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          ¿Qué es la neurodiversidad?
        </MKTypography>
      </Grid>
    </Container>
    <Container>
      <Grid container alignItems="center">
        <Grid item xs={12} lg={5}>
          <MKTypography variant="body2" mb={2}>
            La Neurodiversidad es un concepto en constante evolución y evaluación, por ello aún no
            hay un consenso en su definición ; el intentarlo es enriquecedor, pues tiene un efecto
            catalizador, lo cual hace converger aspectos educativos, sociales, laborales e incluso
            políticos y podemos destacar 4 elementos fundamentales en su entendimiento y
            construcción.
          </MKTypography>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          <Stack>
            <MKBox display="flex" alignItems="center" p={1}>
              <MKBox
                width="10px"
                height="10px"
                variant="gradient"
                bgColor="primary"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              />
              <MKTypography variant="body2" pl={2}>
                Hace referencia a la diversidad existente en la mente y los cerebros de los
                <br />
                individuos de la especie humana, una variación infinita en el comportamiento
                <br />
                neurocognoscitivo atípico.
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" p={1}>
              <MKBox
                width="10px"
                height="10px"
                variant="gradient"
                bgColor="primary"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              />
              <MKTypography variant="body2" pl={2}>
                Hay divergencias neurológicas en la población humana (personas neurodivergentes);
                <br />
                estas condiciones deben ser entendidas como una diferencia cotidiana y no como una
                <br />
                enfermedad que debe ser curada.
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" p={1}>
              <MKBox
                width="10px"
                height="10px"
                variant="gradient"
                bgColor="primary"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              />
              <MKTypography variant="body2" pl={2}>
                Implica un movimiento de justicia social que busca igualdad de derechos y plena
                <br />
                inclusión social.
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" p={1}>
              <MKBox
                width="10px"
                height="10px"
                variant="gradient"
                bgColor="primary"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              />
              <MKTypography variant="body2" pl={2}>
                Las categorías éticas de aceptación, dignidad humana y pertenencia, son el sustento
                <br />
                principal para posicionarse ante la sociedad.
              </MKTypography>
            </MKBox>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </MKBox>
);

export default IntroSection;
