/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

export function InformationSection({ programs }) {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid xs={12} sx={{ mb: "12px" }}>
          <MKTypography
            variant="h2"
            color="primary"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            {programs?.title}
          </MKTypography>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="adjust"
                    title={programs?.card1.title}
                    description={programs?.card1.desc}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title={programs?.card2.title}
                    description={programs?.card2.desc}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="info"
                    title={programs?.card3.title}
                    description={programs?.card3.desc}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title={programs?.card4.title}
                    description={programs?.card4.desc}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={programs?.imgInfo.src}
              title={programs?.imgInfo.title}
              description={programs?.imgInfo.desc}
              action={{
                type: "internal",
                route: "/pages/lading-pages/contact-us",
                color: "info",
                label: "Más información",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export function InformationLeftSection({ programs }) {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid xs={12} sx={{ mb: "24px", textAlign: "right" }}>
          <MKTypography
            variant="h2"
            color="secondary"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            {programs?.title}
          </MKTypography>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={4} sx={{ mr: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={programs?.imgInfo.src}
              title={programs?.imgInfo.title}
              description={programs?.imgInfo.desc}
              action={{
                type: "internal",
                route: "/pages/lading-pages/contact-us",
                color: "info",
                label: "Más información",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title={programs?.card1.title}
                    description={programs?.card1.desc}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title={programs?.card2.title}
                    description={programs?.card2.desc}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="info"
                    title={programs?.card3.title}
                    description={programs?.card3.desc}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title={programs?.card4.title}
                    description={programs?.card4.desc}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
