/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
import AlliansSection from "./AlliansSection";

function LandingTestimonials() {
  return (
    <MKBox component="section" py={12} id="LandingTestimonios">
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Testimonios</MKTypography>
          <MKTypography variant="h2" color="secondary" textGradient mb={2}>
            + 1,000 personas ayudadas
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            A través de diversos programas, la organización ha ayudado a más de ### mexicanos.
            Agrupándolos en:
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="ATENCIÓN TEMPRANA"
              review="De 45 días de nacido a 6 años y 11 meses"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="ATENCIÓN ESCOLARIZADA"
              review="Entre 7 años a 15 años y 11 meses"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="ATENCIÓN JUVENIL Y ADULTA"
              review="Jóvenes de 16 años en adelante"
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <AlliansSection />
      </Container>
    </MKBox>
  );
}

export default LandingTestimonials;
