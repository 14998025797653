// Images
import code from "assets/images/aliados/code.png";
import confe from "assets/images/aliados/confe.png";
import deportivotoluca from "assets/images/aliados/deportivotoluca.png";
import funsimi from "assets/images/aliados/funsimi.png";
import gcm from "assets/images/aliados/gcm.png";
import inovarepp from "assets/images/aliados/inovarepp.png";
import oem from "assets/images/aliados/oem.png";
import proacceso from "assets/images/aliados/proacceso.png";
import rece from "assets/images/aliados/rece.png";
import res from "assets/images/aliados/res.png";
import rie from "assets/images/aliados/rie.png";
import uaemex from "assets/images/aliados/uaemex.png";
import uka from "assets/images/aliados/uka.png";
import unipre from "assets/images/aliados/unipre.png";
import unitec from "assets/images/aliados/unitec2.png";
import univermil from "assets/images/aliados/univermil.png";
import usaer from "assets/images/aliados/usaer.png";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function AlliansSection() {
  return (
    <MKBox component="section" py={12} id="LandingTestimonios">
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
          mb={12}
          id="LandingAliados"
        >
          <MKTypography variant="h2">Aliados</MKTypography>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={code} alt="Apple" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={confe} alt="Facebook" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={deportivotoluca} alt="Nasa" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={funsimi} alt="Vodafone" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={gcm} alt="Apple" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={inovarepp} alt="Facebook" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={oem} alt="Nasa" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={proacceso} alt="Vodafone" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={rece} alt="Apple" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={res} alt="Facebook" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={rie} alt="Nasa" width="60%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={uaemex} alt="Vodafone" width="80%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={uka} alt="Apple" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={unipre} alt="Facebook" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={unitec} alt="Nasa" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={univermil} alt="Vodafone" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKTypography component="a" href="https://twitter.com/cappdi" className="allyContainer">
              <MKBox component="img" src={usaer} alt="DigitalOcean" width="100%" opacity={0.8} />
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AlliansSection;
