/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/bg-contacto.jpeg";

import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { Link } from "@mui/material";
import LandingFooter from "../Author/sections/LandingFooter";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  // eslint-disable-next-line no-shadow
  const isValidForm = (name, email, comments) =>
    name.length > 0 && email.length > 0 && comments.length > 0;

  const form = useRef();

  const cleanForm = () => {
    setName("");
    setEmail("");
    setComments("");
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (isValidForm(name, email, comments)) {
      emailjs
        .sendForm("service_t9hket8", "template_j4txp68", form.current, "uUvhqbq0IBp9QpYIH")
        .then(
          (result) => {
            console.log(result);
            Swal.fire({
              title: "¡Enviado!",
              text: "El correo se ha enviado de manera correcta",
              icon: "success",
              confirmButtonText: "Cool",
            });
            cleanForm();
          },
          (error) => {
            Swal.fire({
              title: "Error!",
              text: `Algo sucedió: ${error}`,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        );
    } else {
      Swal.fire({
        title: "Error!",
        text: "Verifique los campos requeridos",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar routes={routes} />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.3),
                  rgba(gradients.dark.state, 0.3)
                )}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                ¡Contáctanos!
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={5}>
                Recibirás respuesta por parte de uno de nuestros asesores a la brevedad
              </MKTypography>
              <MKBox
                width="100%"
                component="form"
                method="post"
                autocomplete="off"
                ref={form}
                onSubmit={sendEmail}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      value={name}
                      onChange={(e) => setName(e.currentTarget.value)}
                      variant="standard"
                      label="Nombre Completo"
                      placeholder="Nombre Completo"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      name="user_name"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                      variant="standard"
                      label="Correo electrónico"
                      placeholder="ejemplo@gmail.com"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      name="user_email"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      value={comments}
                      onChange={(e) => setComments(e.currentTarget.value)}
                      variant="standard"
                      label="Tengo dudas sobre"
                      placeholder="Me gustaría tener más información..."
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      multiline
                      name="comments"
                      rows={6}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                    Enviar
                  </MKButton>
                </Grid>
              </MKBox>
              <MKBox>
                <Grid item px={0}>
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pr={6} pl={{ xs: 6 }} my="auto">
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="button" color="textGreen">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="textGreen"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          722 489 4872
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="button" color="textGreen">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="textGreen"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          55 1602 1490
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="textGreen" p={1}>
                        <MKTypography variant="button" color="textGreen">
                          <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="textGreen"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          contacto.cappdi@gmail.com
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="textGreen" p={1}>
                        <MKTypography variant="button" color="textGreen">
                          <i className="fas fa-map-marker-alt" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="textGreen"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          LAGUNA ENCANTADA 122 COL BENITO JUAREZ,50190, Toluca de Lerdo
                        </MKTypography>
                      </MKBox>
                      <MKBox mt={3} textAlign="center">
                        <MKButton
                          component={Link}
                          href="https://www.facebook.com/CAPPDI"
                          target="_blank"
                          variant="text"
                          color="textGreen"
                          size="large"
                          iconOnly
                        >
                          <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                        <MKButton
                          component={Link}
                          href="https://twitter.com/cappdi"
                          target="_blank"
                          variant="text"
                          color="textGreen"
                          size="large"
                          iconOnly
                        >
                          <i className="fab fa-twitter" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                        <MKButton
                          component={Link}
                          href="https://www.instagram.com/a.c.cappdi/"
                          target="_blank"
                          variant="text"
                          color="textGreen"
                          size="large"
                          iconOnly
                        >
                          <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <LandingFooter />
      </MKBox>
    </>
  );
}

export default ContactUs;
