/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";

// Material Kit 2 React routes
import routes from "routes";

import "App.css";
import Presentation from "pages/Presentation";
import MKButton from "components/MKButton";
import Swal from "sweetalert2";
import PayPalLogo from "assets/images/paypal_logo.png";

const goDonate = () => {
  Swal.fire({
    title: "¡Gracias por apoyar!",
    text: "Para CAPPDI es muy importante que nos apoyes, te redigiremos a nuestra página para donativos",
    icon: "info",
    confirmButtonText: "¡Vamos!",
    showDenyButton: true,
    denyButtonText: "No por el momento",
  }).then((response) => {
    if (response.isConfirmed) {
      window.open("https://www.paypal.com/mx/home", "_blank");
    }
  });
};

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <MKButton id="paypal-btn" variant="gradient" color="warning" onClick={() => goDonate()}>
        <div>
          <p style={{ padding: 0, margin: 0 }}>¡Apóyanos!</p>
          <img src={PayPalLogo} alt="Paypal logo" width={112} />
        </div>
      </MKButton>
    </ThemeProvider>
  );
}
