/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import MKTypography from "components/MKTypography";
import { Divider } from "@mui/material";
import Places from "./Posts";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid xs={12} sx={{ mb: "12px" }} textAlign="center">
          <MKTypography
            variant="h2"
            color="primary"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            ¿Cómo nos apoyamos?
          </MKTypography>
        </Grid>
        <Grid sx={{ mb: "3rem" }}>
          <MKTypography mb={1.5} variant="body2">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Excepturi error tenetur
            quibusdam eveniet accusamus, quo iure molestiae omnis perspiciatis. Explicabo cumque
            deserunt aspernatur nisi quaerat fugit molestiae ut dolores est?
          </MKTypography>
          <MKTypography mb={1.5} variant="body2">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum delectus, nesciunt itaque
            dolorum aspernatur explicabo, error mollitia ducimus et fuga repellendus iste nostrum
            neque laboriosam eius inventore? Maxime, odit magni.|
          </MKTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Places />
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
  );
}

export default Information;
