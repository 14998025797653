/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import video1 from "assets/images/neurodiversidad/video1.png";

export default [
  {
    image: `${video1}`,
    name: "Video muestra 1",
    route: "https://www.youtube.com/watch?v=V-vUCPDwCB4",
  },
  {
    image: `${video1}`,
    name: "Video muestra 2",
    route: "https://www.youtube.com/watch?v=V-vUCPDwCB4",
  },
  {
    image:
      "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/account/signin-basic.jpg",
    name: "Video muestra 3",
    route: "https://www.youtube.com/watch?v=V-vUCPDwCB4",
  },
  {
    image: `${video1}`,
    name: "Video muestra 4",
    route: "https://www.youtube.com/watch?v=V-vUCPDwCB4",
  },
];
