/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { InformationSection, InformationLeftSection } from "./InformationSection";
import IntroSection from "./IntroSection";

function Information() {
  const programs = [
    {
      title: "Programa de Atención a la Discapacidad",
      card1: {
        title: "Objetivo",
        desc: "Creamos programas de intervención mediante el Modelo CAPPDI, (áreas de trabajo sensorial, conductual, social, académica y de vida independiente) que en conjunto con los padres de familia buscamos lograr el mayor grado de autonomía e independencia del usuario.",
      },
      card2: {
        title: "Para quien",
        desc: "Niñas, niños y adolescentes de 2 años a 15 años de edad con discapacidad intelectual, discapacidad motriz y/o condición del espectro autista. (Síndrome de down, parálisis cerebral infantil, epilepsia, alteraciones y/o afecciones genéticas y/o cromosómicas)",
      },
      card3: {
        title: "Servicios",
        desc: "Grupos de 5 alumnos distribuidos según su edad y discapacidad trabajando sobre estos tres ejes: Habilidades Básicas y de vida independiente, Habilidades socio adaptivas Habilidades académicas",
      },
      card4: {
        title: "Atención Temprana / Atención Escolarizada",
        desc: "Niños y adolescentes con discapacidad intelectual y/o motriz desde los 45 días de nacido a los 15 años Síndromes y/o trastornos que atendemo",
      },
      imgInfo: {
        title: "HORARIOS",
        src: "https://images.pexels.com/photos/16853260/pexels-photo-16853260/free-photo-of-mar-ciudad-restaurante-paisaje.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        desc: "Lunes a viernes de 8:00 a.m. a 2:00 p.m.",
      },
    },
    {
      title: "Programa de Inclusión Escolar, Social y Laboral",
      card1: {
        title: "Objetivo",
        desc: "Brindar servicios Psicopedagógico especializados a niños, jóvenes y adultos con discapacidad Intelectual, motriz y/o Trastorno del Espectro Autista que garantice una plena inclusión a la sociedad",
      },
      card2: {
        title: "Servicios",
        desc: "Establecer las estrategias conductuales y académicas que permitan una inclusión escolar positiva y funcional para el alumno y la comunidad escolar",
      },
      card3: {
        title: "Perfil de ingreso",
        desc: "Niños y adolescentes con discapacidad intelectual, motriz y Trastorno del Espectro Autista desde los 3 años a los 15 años de edad que presenten la habilidades conductuales y sociales requeridas para una inclusión escolar funcional.",
      },
      card4: {
        title: "Perfil de ingreso",
        desc: "Niños y adolescentes con discapacidad intelectual, motriz y Trastorno del Espectro Autista desde los 3 años a los 15 años de edad que presenten la habilidades conductuales y sociales requeridas para una inclusión escolar funcional.",
      },
      imgInfo: {
        title: "HORARIOS",
        src: "https://images.pexels.com/photos/16853260/pexels-photo-16853260/free-photo-of-mar-ciudad-restaurante-paisaje.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        desc: "Los marcados por les escuelas públicas y/o privadas donde se realice la inclusión escolar",
      },
    },
    {
      title: "Programa psicopedagógica y de rehabilitación para la familia",
      card1: {
        title: "Apoyo Psicopedagógico y Emocional Infantil",
        desc: `Atención a situaciones conductuales.\n
        Atención a situaciones emocionales. \n
        Atención a situaciones académicas.\n
        Atención a situaciones de lenguaje como.\n
        Sesiones individuales de terapia física y/o ocupacional pediátrica y escolar.`,
      },
      card2: {
        title: "Programa de terapia física y ocupacional para la familiar",
        desc: "Rehabilitación al adulto y geriátrica sesión individual de terapia física y ocupacional. Rehabilitación deportiva y/o post accidentes automovilísticos.",
      },
      card3: {
        title: "Atención emocional para la Mujer",
        desc: "Terapia individual para la mujer. Terapia de pareja.Talleres para madres y padres.",
      },
      card4: {
        title: "Perfil de ingreso",
        desc: "Niños y adolescentes con discapacidad intelectual, motriz y Trastorno del Espectro Autista desde los 3 años a los 15 años de edad que presenten la habilidades conductuales y sociales requeridas para una inclusión escolar funcional.",
      },
      imgInfo: {
        title: "Perfil de ingreso",
        src: "https://images.pexels.com/photos/16853260/pexels-photo-16853260/free-photo-of-mar-ciudad-restaurante-paisaje.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        desc: "Madres de familia y mujeres desde los 18 años en adelante.",
      },
    },
  ];
  return (
    <>
      <IntroSection />
      <InformationSection programs={programs[0]} />
      <InformationLeftSection programs={programs[1]} />
      <InformationSection programs={programs[2]} />
    </>
  );
}

export default Information;
