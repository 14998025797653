/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import progragrama1 from "assets/images/programas/programa1.jpg";
import progragrama2 from "assets/images/programas/programa2.jpg";
import progragrama3 from "assets/images/programas/programa3.jpg";
import progragrama4 from "assets/images/programas/programa4.jpg";
import progragrama5 from "assets/images/programas/programa5.jpg";
import progragrama6 from "assets/images/programas/programa6.jpg";
import progragrama7 from "assets/images/programas/programa7.jpg";
import progragrama8 from "assets/images/programas/programa8.jpg";

export default [
  {
    title: "Atención a la Discapacidad",
    description:
      "Brindar servicios psicopedagógicos y de rehabilitación a niños y adolescentes con discapacidad intelectual y/o motriz mediante un modelo de trabajo incluyente, accesible y lúdico que en conjunto con el acompañamiento y capacitación de la familia propicie un desarrollo integral efectivo y afectivo del alumno.",
    items: [
      {
        image: `${progragrama1}`,
        route: "/pages/lading-pages/programs",
      },
      {
        image: `${progragrama2}`,
        route: "/pages/lading-pages/programs",
      },
      {
        image: `${progragrama3}`,
        route: "/pages/lading-pages/programs",
      },
    ],
  },
  {
    title: "Inclusión Escolar, Social y Laboral",
    description:
      "Brindar servicios integrales especializados a niños, jóvenes y adultos con discapacidad Intelectual, motriz y/o trastorno del espectro autista que garanticen una plena inclusión a la sociedad.",
    items: [
      {
        image: `${progragrama4}`,
        route: "/pages/lading-pages/programs",
      },
      {
        image: `${progragrama5}`,
        route: "/pages/lading-pages/programs",
      },
      {
        image: `${progragrama6}`,
        route: "/pages/lading-pages/programs",
      },
    ],
  },
  {
    title: "Apoyo Psicopedagógico y Rehabilitación Física para la Familia",
    description: "lorem ipsum",
    items: [
      {
        image: `${progragrama7}`,
        route: "/pages/lading-pages/programs",
      },
      {
        image: `${progragrama8}`,
        route: "/pages/lading-pages/programs",
      },
      {
        image: `${progragrama1}`,
        route: "/pages/lading-pages/programs",
      },
    ],
  },
];
